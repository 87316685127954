module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TXBZ8M","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":0},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Intima","short_name":"Intima","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"/home/vsts/work/1/s/packages/gatsby-theme-husky/static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"380eeee2e627d324e2f84d8e73db2f7e"},
    },{
      plugin: require('../../../node_modules/@phx-husky/gatsby-plugin-static-search/gatsby-browser.js'),
      options: {"plugins":[],"queries":[{"query":"{\n  allProductDetailPage {\n    nodes {\n      url\n      id\n      lang\n      filterTags {\n        id\n        tagsTaxonomy {\n          taxonomy\n        }\n      }\n      tags {\n        id\n        tag\n      }\n    }\n  }\n}","indexName":"intima-fr-uat-filters","settings":{"attributesForFaceting":["searchable(lang)","searchable(tags)","searchable(stage1)","searchable(stage2)","searchable(pro1)"]}},{"query":"\n  {\n    allProductDetailPage {\n      nodes {\n        url\n        objectID: id\n        productName\n        productDescription\n        tags {\n          tag\n        }\n        features {\n          badge {\n            label\n          }\n        }\n        badge {\n          label\n        }\n      }\n    }\n  }\n","indexName":"intima-fr-uat-products","settings":{"searchableAttributes":["productName","url","productDescription","tag","label"]}},{"query":"\n  {\n    allArticleDetailPage {\n      nodes {\n        objectID: id\n        url\n        tags {\n          tag\n        }\n        card {\n          title\n          description\n        }\n      }\n    }\n  }\n","indexName":"intima-fr-uat-articles","settings":{"searchableAttributes":["url","tag","title","description"]}}]},
    },{
      plugin: require('../../gatsby-debug-log/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-husky/gatsby-browser.tsx'),
      options: {"plugins":[],"themeConfig":{}},
    }]
